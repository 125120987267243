<template>
  <div :style="background" class="payment_status">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100">
        <CCol cols="12" lg="12">
          <CCard class="p-0 m-auto">
            <CCardBody class="p-0 text-center">
              <div v-if="paymentStatusDetailsLoaded === false">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <loadingSpinner mode="auto" :content="$t('payments.Checking_payment_status')"/>
                  </CCol>
                </CRow>
              </div>
              <div v-else-if="paymentStatusDetailsLoaded === true && paymentAvailable === true">
                <!-- Initial Payments -->
                <CRow v-if="paymentSequenceType === 'first'">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <!-- Paid -->
                    <div v-if="paymentStatus === 'paid'">
                      <h1 class="mb-2">{{$t('payments.Recurring_payments_setup_successful')}}</h1>
                      <span v-html="$t('payments.Recurring_payments_have_been_setup_succesfully')"></span>
                    </div>
                    <!-- Expired -->
                    <div v-else-if="paymentStatus === 'expired'">
                      <h1 class="mb-2">{{$t('payments.Payment_expired')}}</h1>
                      <span>{{$t('payments.Verification_payment_has_expired')}} {{$t('payments.Create_a_new_payment_in_the_dashboard', {environment_name : environmentVariables.environment_name})}}</span>
                    </div>
                    <!-- Canceled -->
                    <div v-else-if="paymentStatus === 'canceled'">
                      <h1 class="mb-2">{{$t('payments.Payment_canceled')}}</h1>
                      <span>{{$t('payments.Verification_payment_has_been_canceled')}} {{$t('payments.Create_a_new_payment_in_the_dashboard', {environment_name : environmentVariables.environment_name})}}</span>
                    </div>  
                    <!-- Failed -->
                    <div v-else-if="paymentStatus === 'failed'">
                      <h1 class="mb-2">{{$t('payments.Payment_failed')}}</h1>
                      <span>{{$t('payments.Verification_payment_has_failed')}} {{$t('payments.Payment_cannot_be_completed_with_different_method')}}</span>
                    </div>
                  </CCol>
                </CRow>
                <!-- Prepaid Payments -->
                <CRow v-else-if="paymentSequenceType === 'oneoff'">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <!-- Paid -->
                    <div v-if="paymentStatus === 'paid'">
                      <h1 class="mb-2">{{$t('payments.Wallet_balance_top_up_successful')}}</h1>
                      <span v-html="$t('payments.Wallet_balance_has_been_topped_up_succesfully')"></span>
                    </div>
                    <!-- Expired -->
                    <div v-else-if="paymentStatus === 'expired'">
                      <h1 class="mb-2">{{$t('payments.Payment_expired')}}</h1>
                      <span>{{$t('payments.Wallet_top_up_payment_has_expired')}} {{$t('payments.Create_a_new_payment_in_the_dashboard', {environment_name : environmentVariables.environment_name})}}</span>
                    </div>
                    <!-- Canceled -->
                    <div v-else-if="paymentStatus === 'canceled'">
                      <h1 class="mb-2">{{$t('payments.Payment_canceled')}}</h1>
                      <span>{{$t('payments.Wallet_top_up_payment_has_been_canceled')}} {{$t('payments.Create_a_new_payment_in_the_dashboard', {environment_name : environmentVariables.environment_name})}}</span>
                    </div>  
                    <!-- Failed -->
                    <div v-else-if="paymentStatus === 'failed'">
                      <h1 class="mb-2">{{$t('payments.Payment_failed')}}</h1>
                      <span>{{$t('payments.Wallet_top_up_payment_has_failed')}} {{$t('payments.Payment_cannot_be_completed_with_different_method')}}</span>
                    </div>
                  </CCol>
                </CRow>

                <CRow v-if="paymentStatus" class="mt-4">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <div class="icon_container">
                      <!-- Paid -->
                      <i v-if="paymentStatus === 'paid'" class="fa-solid fa-circle-check"></i>
                      <!-- Expired -->
                      <i v-else-if="paymentStatus === 'expired'" class="fa-solid fa-clock"></i>
                      <!-- Canceled -->                      
                      <i v-else-if="paymentStatus === 'canceled'" class="fa-solid fa-circle-exclamation"></i>
                      <!-- Failed -->
                      <i v-else-if="paymentStatus === 'failed'" class="fa-solid fa-circle-xmark"></i>
                    </div>
                  </CCol>
                </CRow>
                
                <CRow v-if="isLoggedIn" class="mt-4">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <CButton color="primary" @click="openDashboardHomePage()">
                      <span><i class="fas fa-house mr-2"/>{{$t('common.Back_to_home')}}</span>
                    </CButton>
                  </CCol>
                </CRow>
              </div>
              <div v-else-if="paymentStatusDetailsLoaded === true && paymentAvailable === false">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <h1 class="mb-2">{{$t('payments.Payment_not_found')}}</h1>
                    <span v-html="$t('payments.No_information_found_for_payment')"></span>
                  </CCol>
                </CRow>                
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>  
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'RecurringPaymentsSetupSuccessful',
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
  components: {
    loadingSpinner
  },  
  data() {
    return {
      paymentIdExternal: null,
      paymentStatusDetailsLoaded: false,
      paymentAvailable: false,
      paymentStatus: null,
      paymentSequenceType: null,
      background : {
        backgroundImage: "linear-gradient(45deg, rgba(0, 104, 89, 0.9), rgba(48, 146, 139, 0.95)), url('./img/login/harry_hr_pattern.png')",
        backgroundRepeat: 'repeat',
      }   
    }
  },
  methods: {
    getPaymentStatusDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payment/' + this.paymentIdExternal + '/status')
      .then(res => {      
        let paymentStatusDetails = res.data.data;
        // Set the paymentAvailable value
        this.paymentAvailable = paymentStatusDetails.payment_available;        
        // Set the paymentStatus value
        this.paymentStatus = paymentStatusDetails.payment_status;
        // Set the paymentSequenceType value
        this.paymentSequenceType = paymentStatusDetails.payment_sequence_type;
        // Update the paymentStatusDetailsLoaded value
        this.paymentStatusDetailsLoaded = true;
      })
      .catch(err => {
        console.error(err);
      });      
    },
    getEnvironmentVariables() {
      // Get the hostname
      this.platformHostname = window.location.hostname;
      // Set the params
      let params = {};
      params.hostname = this.platformHostname;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/platform/environments/dashboard', params)
      .then(res => {
        this.environmentVariables = res.data.data;
        // Set the dashboard title
        document.title = this.environmentVariables.environment_name + ' - ' + this.$t('payments.Recurring_payments_setup_successful');
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.environmentVariables.primary_color);        
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', this.environmentVariables.primary_color);
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', this.environmentVariables.secondary_color);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.environmentVariables.font_family);
        // Set the background image
        this.background.backgroundImage = "linear-gradient(45deg, rgba(" + this.environmentVariables.primary_color_rgb + ", 0.85), rgba(" + this.environmentVariables.primary_color_rgb + ", 0.9)), url('./img/login/harry_hr_pattern.png')";
        
        // Set the favicon href and the placeholder for the email field
        if(this.environmentVariables.environment_tag ==='harryhr') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/harry_hr_favicon_23_06_2022.png';          
        } else if(this.environmentVariables.environment_tag ==='employalty') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/employalty_favicon.png';        
        }        
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getCompanyVariables() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/variables/dashboard/company')
      .then(res => {
        this.companyVariables = res.data.data;
        // Set the dashboard title
        document.title = this.companyVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.companyVariables.environment_primary_color);        
        // Set default color variables
        let primaryColor = null;
        let secondaryColor = null;  
        // Set the primaryColor value
        (this.companyVariables.primary_color) ? primaryColor = this.companyVariables.primary_color : primaryColor = this.companyVariables.environment_primary_color;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', primaryColor);
        // Set the primaryColor value
        (this.companyVariables.secondary_color) ? secondaryColor = this.companyVariables.secondary_color : secondaryColor = this.companyVariables.environment_secondary_color;
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', secondaryColor);
        // Set the dashboard font
        if(this.companyVariables.font_family) document.documentElement.style.setProperty('--DashboardFontFamily', this.companyVariables.font_family);
        // Update the companyImageId data in localStorage
        if(this.companyVariables.company_image_id) localStorage.setItem('companyImageId', this.companyVariables.company_image_id);
        // Update the headerImageId data in localStorage
        if(this.companyVariables.header_image_id )localStorage.setItem('headerImageId', this.companyVariables.header_image_id);
        // Update the environmentTag data in localStorage
        if(this.companyVariables.environment_tag) localStorage.setItem('environmentTag', this.companyVariables.environment_tag);
        // Set the dashboard background image URL
        document.documentElement.style.setProperty('--DashboardBackgroundImage', 'url(' + process.env.VUE_APP_CDN_URL + '/base/app/harry-hr-experience-background.png)');
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    openDashboardHomePage() {
      // Get the company variables
      this.getCompanyVariables();
      // Navigate to the dashboard homepage
      if(this.$router.currentRoute.path !== '/')  this.$router.replace({path: '/'});      
    }    
  },
  mounted: function() {
    // Set the base URL for the CDN
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    // Get the environment variables
    this.getEnvironmentVariables();

    setTimeout(function() {                      
      if(this.$route.params.id) {
        // Set the paymentIdExternal value
        this.paymentIdExternal = this.$route.params.id;
        // Get the payment status
        this.getPaymentStatusDetails();  
      }    
    }.bind(this), 250); 
  }  
}
</script>
